import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const TeamCard = ({ name, image, text, link, role }) => {
  return (
    <>
      <div className="col-12 mb-8 d-flex flex-row flex-wrap flex-md-nowrap">
        <Img fixed={image} alt={name} className="rounded m-5 shadow-blue-green flex-shrink-0" />
        <div className="m-5 text-left">
          <h3 className="text-uppercase text-gray-900 font-weight-bold">
            {name}
            <br />
            <span className="text-success">{role}</span>
          </h3>
          <blockquote className="mb-5">"{text}"</blockquote>
          <a href={link} target="_blank" rel="noopener noreferrer" aria-label={name}>
            <FontAwesomeIcon icon={faLinkedin} color="grey" />
          </a>
        </div>
      </div>
    </>
  )
}

TeamCard.propTypes = {
  name: PropTypes.node.isRequired,
  image: PropTypes.node,
  text: PropTypes.node,
  link: PropTypes.node,
}

export default TeamCard
