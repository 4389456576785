import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MoreBlogPosts from "../components/more-blog-posts"
import TeamCard from "../components/team-card"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGift,
  faCheck,
  faCalendarTimes,
} from "@fortawesome/free-solid-svg-icons"

class Webinar extends React.Component {
  componentDidMount() {
    const script = document.createElement("script")
    script.src = "//js.hsforms.net/forms/shell.js"
    script.charset = "utf-8"
    document.body.appendChild(script)

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "6716078",
          formId: "4931e346-4e0e-4004-a2c3-d5e2e7eb8aac",
          target: "#hubspotForm",
        })
      }
    })
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Webinar: Data Protection meets Mobile Mapping - Celantur"
          description="Join us at 5:00 PM CET, 16th of September, Tuesday for getting practical tips on data privacy implications for mobile mapping."
        />

        <section className="container pt-5 my-5 text-center">
          <h1 className="display-3 font-weight-bold my-5">
            Webinar:
            <br />
            <span className="text-success">
              Data Protection meets Mobile Mapping
            </span>
          </h1>
          <p className="lead">
            Join us at Tuesday, <mark>September 16th</mark> at{" "}
            <mark>5:00 PM CET</mark> on Google Meet.
            <br />
            Get practical tips on data privacy implications for mobile mapping
            and an overview of the GDPR &amp; other privacy regulations.
          </p>
        </section>

        <section className="my-10">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <Img
                  fluid={this.props.data.visual.childImageSharp.fluid}
                  className="img-fluid rounded mx-5"
                />
              </div>
              <div className="col-12 col-md-6">
                <p>In this free webinar you will learn:</p>
                <div className="d-flex">
                  <div className="badge badge-rounded-circle badge-primary mt-1 mr-4">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <p className="text-gray-700">
                    Why non-anonymized images could lead to legal consequence
                    according to data privacy regulations
                  </p>
                </div>
                <div className="d-flex">
                  <div className="badge badge-rounded-circle badge-primary mt-1 mr-4">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <p className="text-gray-700">
                    Fines and compensations foreseen by data protection laws
                  </p>
                </div>
                <div className="d-flex">
                  <div className="badge badge-rounded-circle badge-primary mt-1 mr-4">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <p className="text-gray-700">
                    Legal requirements and documentation to meet contract
                    demands from public tenders
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="d-flex mt-8 justify-content-center">
                  <div className="badge badge-rounded-circle badge-success mt-1 mr-4">
                    <FontAwesomeIcon icon={faGift} />
                  </div>
                  <p className="text-gray-700">
                    Each participant will receive 1000 free images to try out
                    our anonymization solution!
                  </p>
                </div>

                <div className="d-flex justify-content-center">
                  <div className="badge badge-rounded-circle badge-warning mt-1 mr-4">
                    <FontAwesomeIcon icon={faCalendarTimes} />
                  </div>
                  <p className="text-gray-700">
                    Can't make it? Don’t worry, subscribe and we'll send you a link to the
                    full recording via email.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="container pt-5 my-5 text-center">
          <h2>
            Sign-up to the webinar <span className="color-success">now</span>
          </h2>
          <section id="hubspotForm"></section>
        </div>

        <section className="container pt-5 my-5 text-center">
          <h2 className="text-center mb-5">Guest &amp; Host</h2>

          <div className="row align-items-center justify-content-center">
            <TeamCard
              name="Arnold Redhammer"
              color="#4285f4"
              image={this.props.data.imageArnold.childImageSharp.fluid}
              text="Arnold Redhammer is an external data protection officer for companies in Austria and Germany, as well as a passionate OKR consultant. He has been active in the broad field of digitization since 2001."
              role="Guest"
            />

            <TeamCard
              name="Alexander Petkov"
              color="#4285f4"
              image={this.props.data.imageAlex.childImageSharp.fluid}
              text="Alexander Petkov is the founder &amp; CEO of Celantur. He has more than 12 years experience in software and product development. Former Project Manager and Team Lead Software Development."
              role="Host"
            />
          </div>
        </section>

        <MoreBlogPosts
          caption="Latest Blog Posts on Mobile Mapping"
          posts={this.props.data}
        />
      </Layout>
    )
  }
}

export default Webinar

export const query = graphql`
  fragment personImage on File {
    childImageSharp {
      fluid(maxWidth: 200) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  query {
    imageAlex: file(relativePath: { eq: "celantur-logo.png" }) {
      ...personImage
    }
    imageArnold: file(relativePath: { eq: "celantur-logo.png" }) {
      ...personImage
    }
    visual: file(
      relativePath: { eq: "webinar-data-protection-meets-mobile-mapping.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: ["mobile mapping"] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            tags
            path
            teaser
            date
          }
        }
      }
    }
  }
`
